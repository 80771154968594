import React from "react";
import { ScrollView, View, Text, StyleSheet, Image } from "react-native";

import {
  GTAmericaCompressed,
  greenBrand,
  blackBrand,
  TrailersDemiBold,
  PanamaBold,
  GTAmerica,
} from "../libs/constants";
import { CuratorHeader } from "../components/curator/CuratorHeader";
import { PhoneNumberSignupForm } from "../components/forms/PhoneNumberSignupForm";
import { EventSlice } from "../components/events/EventSlice";
import { RouteComponentProps } from "../navigation";
import { useEffect } from "react";
import { mdBreakpoint } from "../constants";
import { useDimensions } from "../context/DimensionsContext";
import { HoverableLink } from "../components/common/HoverableLink";
import { useThemeState } from "../context/ThemeContext";
import { H1, P } from "@expo/html-elements";
import { TextureUvs } from "pixi.js";

interface Props { }

export const EventsLandingScreen: React.FC<
  RouteComponentProps<{
    isSmsSubscriber: string;
  }>
> = (props: RouteComponentProps<{ isSmsSubscriber: string }>) => {
  document.title = "Events | Happy Medium";
  const scrollRef = React.useRef(null);
  const { width } = useDimensions();
  const { theme } = useThemeState();
  const {
    match: {
      params: { isSmsSubscriber },
    },
  } = props;

  const subscribed = isSmsSubscriber === "subscribed";
  console.log(
    "SMS??",
    isSmsSubscriber,
    isSmsSubscriber === "subscribed",
    subscribed
  );
  const measuringTape = require("@happymedium/components/assets/images/blue-white-measure-tape-long-horizontal.png");
  const styles = StyleSheet.create({
    infoText: {
      fontFamily: GTAmericaCompressed,
      fontWeight: "300",
      fontSize: 24,
      color: blackBrand,
    },
    body: {
      color: theme.foregroundColor,
      fontFamily: GTAmerica,
      fontSize: width < mdBreakpoint ? 14 : 16,
    },
  });

  const mobileEventHeight = 740;
  const desktopEventHeight = 480;

  useEffect(() => {
    if (subscribed) {
      //@ts-ignore
      scrollRef.current!.scrollTo({
        y:
          width < mdBreakpoint
            ? mobileEventHeight * 9.45
            : desktopEventHeight * 9.74,
      });
    }
  }, []);

  return (
    <ScrollView
      style={{
        flex: 1,
        backgroundColor: theme.backgroundColor,
      }}
      ref={scrollRef}
    >
      <CuratorHeader
      // backgroundColor={greenBrand}
      // hideThemePicker={true}
      // logoColor={'black'}
      // linkColor={blackBrand}
      // isRow={true}
      />
      <View
        style={{
          maxWidth: width < mdBreakpoint ? "90%" : 540,
          marginTop: width < mdBreakpoint ? 20 : 60,
          alignSelf: "center",
          alignItems: "flex-start",
          // paddingLeft: 20,
          // paddingRight: 20,
          backgroundColor: theme.backgroundColor,
        }}
      >
        <H1
          style={{
            color: theme.foregroundColor,
            fontFamily: PanamaBold,
            fontWeight: "normal",
            fontSize: width < mdBreakpoint ? 24 : 38,
            letterSpacing: 1.2,
            width: "100%",
            textTransform: "uppercase",
            alignSelf: "flex-start",
          }}
        >
          Classes & Figure Drawing
        </H1>
        {/* <View style={{ height: 20 }} /> */}
        <Text style={[styles.body]}>
          Happy Medium's events are designed for the adult beginner who wants to
          have fun creating instead of consuming for a few hours with zero
          expectation of being "good enough." To learn more, check out our{" "}
          <HoverableLink
            linkUrl="/events/faq"
            linkType="internal"
            textStyle={{ alignSelf: "center" }}
          >
            <Text style={[styles.body]}>event FAQs</Text>
          </HoverableLink>
          .
          <P style={[styles.body, { fontSize: 14, fontWeight: "bold" }]}>
            **We are unable to accomodate refunds on ticket purchases. You must
            notify us at reservations@happy-medium.co at least 24 hours before
            your time slot in order to be eligible to reschedule. Otherwise, the
            full price will be forfeit.**
          </P>
          {`\n\n`}
          Check out our guide to an evening of figure drawing{" "}
          <HoverableLink
            linkUrl="/events/figure-drawing"
            linkType="internal"
            textStyle={{ alignSelf: "center" }}
          >
            <Text style={[styles.body]}>here</Text>
          </HoverableLink>
          !
        </Text>
      </View>
      {!subscribed && (
        <>
          <View style={{ height: 20 }} />
          <View
            style={{
              backgroundColor: theme.foregroundColor,
              paddingTop: 20,
              paddingBottom: 30,
              width: "100vw",
              maxWidth: 584,
              alignSelf: "center",
            }}
          >
            <Text
              style={[
                styles.infoText,
                {
                  fontSize: 22,
                  fontFamily: TrailersDemiBold,
                  textTransform: "uppercase",
                  letterSpacing: 1.4,
                  textAlign: "center",
                  color: theme.backgroundColor,
                },
              ]}
            >
              Sign up to be the first to know about new events
            </Text>
            <View style={{ alignItems: "center", marginTop: 5 }}>
              <PhoneNumberSignupForm />
            </View>
          </View>
        </>
      )}
      <View style={{ width: 30, height: 40 }} />
      <View />
      <EventSlice
        eventTitle="Figure Drawing"
        eventDate="Monday, April 7th, 2025"
        eventTime="7:00pm - 9:00pm"
        eventImgUrl="https://49market.s3.amazonaws.com/2025-04-07-figure-drawing.png"
        eventSubtitle="with special guest, Laura."
        eventUrl="/event/252"
        soldOut={true}
        ticketUrl="https://buy.stripe.com/7sIcQs6GU7ee92NbY9"
        location="49 Market St, NY, NY 10002"
      />
      <EventSlice
        eventTitle="Figure Drawing: Long Pose Night"
        eventDate="Tuesday, April 8th, 2025"
        eventTime="7:00pm - 9:00pm"
        eventImgUrl="https://49market.s3.amazonaws.com/2025-04-08-long-pose-figure-drawing.png"
        eventSubtitle="with special guest, Ella."
        eventUrl="/event/252"
        soldOut={false}
        ticketUrl="https://buy.stripe.com/cN2bMo6GU2XY3It4vI"
        location="49 Market St, NY, NY 10002"
      />
      <EventSlice
        eventTitle="Figure Drawing"
        eventDate="Wednesday, April 9th, 2025"
        eventTime="7:00pm - 9:00pm"
        eventImgUrl="https://49market.s3.amazonaws.com/2025-04-09-figure-drawing.png"
        eventSubtitle="with special guest, Bola Bee."
        eventUrl="/event/252"
        soldOut={false}
        ticketUrl="https://buy.stripe.com/00gg2E8P28iibaV1jz"
        location="49 Market St, NY, NY 10002"
      />
      <EventSlice
        eventTitle="Figure Drawing 101"
        eventDate="Saturday, April 12th, 2025"
        eventTime="9:00am - 11:00am"
        eventImgUrl="https://49market.s3.amazonaws.com/2025-04-12-figure-drawing-101.png"
        eventSubtitle="with Matthieu Dougherty"
        eventUrl="/event/252"
        soldOut={false}
        ticketUrl="https://buy.stripe.com/bIY5o09T6buu5QBd2p"
        location="49 Market St, NY, NY 10002"
      />
      <EventSlice
        eventTitle="The Art of Watercolor: Still Lives"
        eventDate="Saturday, April 12th, 2025"
        eventTime="9:00am - 11:00am"
        eventImgUrl="https://49market.s3.amazonaws.com/2025-04-12-watercolor-still-life.png"
        eventSubtitle="with Claire Wilson-Oghedo"
        eventUrl="/event/252"
        soldOut={false}
        ticketUrl="https://buy.stripe.com/14kaIk3uIgOO3It8M7"
        location="224 Franlin St, Brooklyn, NY 11222"
      />
      <EventSlice
        eventTitle="The Art of Collage"
        eventDate="Sunday, April 13th, 2025"
        eventTime="9:00am - 11:00am"
        eventImgUrl="https://49market.s3.amazonaws.com/2025-04-13-collage.png"
        eventSubtitle="with James Gallagher"
        eventUrl="/event/252"
        soldOut={false}
        ticketUrl="https://buy.stripe.com/14kdUwaXadCCbaV6E2"
        location="49 Market St, NY, NY 10002"
      />
      <EventSlice
        eventTitle="Figure Drawing"
        eventDate="Monday, April 14th, 2025"
        eventTime="7:00pm - 9:00pm"
        eventImgUrl="https://49market.s3.amazonaws.com/2025-04-14-figure-drawing.png"
        eventSubtitle="with special guest, Bola Bee."
        eventUrl="/event/252"
        soldOut={false}
        ticketUrl="https://buy.stripe.com/00gdUw5CQgOO0wh5zQ"
        location="49 Market St, NY, NY 10002"
      />
      <EventSlice
        eventTitle="Figure Drawing"
        eventDate="Wednesday, April 16th, 2025"
        eventTime="7:00pm - 9:00pm"
        eventImgUrl="https://49market.s3.amazonaws.com/2025-04-16-figure-drawing.png"
        eventSubtitle="with special guest, Lauren Gerrie."
        eventUrl="/event/252"
        soldOut={false}
        ticketUrl="https://buy.stripe.com/eVaeYA7KYfKK0wh2nF"
        location="49 Market St, NY, NY 10002"
      />
      <EventSlice
        eventTitle="Build-a-Lamp"
        eventDate="Saturday, April 19th, 2025"
        eventTime="9:00am - 11:00am"
        eventImgUrl="https://49market.s3.amazonaws.com/2025-04-19-Lamp.png"
        eventSubtitle=""
        eventUrl="/event/252"
        soldOut={false}
        ticketUrl="https://buy.stripe.com/6oE03G6GU1TU1AlgeM"
        location="49 Market St, NY, NY 10002"
      />
      <EventSlice
        eventTitle="Build-a-(Window)-Box"
        eventDate="Sunday, April 20th, 2025"
        eventTime="9:00am - 11:00am"
        eventImgUrl="https://49market.s3.amazonaws.com/2025-04-20-Window-Box.png"
        eventSubtitle=""
        eventUrl="/event/252"
        soldOut={false}
        ticketUrl="https://buy.stripe.com/6oEaIkaXagOO7YJ1jM"
        location="224 Franklin St, Brooklyn, NY 11222"
      />
      <EventSlice
        eventTitle="Figure Drawing"
        eventDate="Monday, April 21st, 2025"
        eventTime="7:00pm - 9:00pm"
        eventImgUrl="https://49market.s3.amazonaws.com/2025-04-21-figure-drawing.png"
        eventSubtitle="with special guest, Tommi."
        eventUrl="/event/252"
        soldOut={false}
        ticketUrl="https://buy.stripe.com/00geYA3uI566a6Rd2k"
        location="49 Market St, NY, NY 10002"
      />
      <EventSlice
        eventTitle="Figure Drawing"
        eventDate="Tuesday, April 22nd, 2025"
        eventTime="7:00pm - 9:00pm"
        eventImgUrl="https://49market.s3.amazonaws.com/2025-04-22-figure-drawing.png"
        eventSubtitle="with special guest, Lauren Gerrie."
        eventUrl="/event/252"
        soldOut={false}
        ticketUrl="https://buy.stripe.com/eVacQs0iwgOObaVgeJ"
        location="49 Market St, NY, NY 10002"
      />
      <EventSlice
        eventTitle="Figure Drawing"
        eventDate="Wednesday, April 23rd, 2025"
        eventTime="7:00pm - 9:00pm"
        eventImgUrl="https://49market.s3.amazonaws.com/2025-04-23-figure-drawing.png"
        eventSubtitle="with special guest, Ella."
        eventUrl="/event/252"
        soldOut={false}
        ticketUrl="https://buy.stripe.com/dR62bO7KY2XYfrbe6j"
        location="49 Market St, NY, NY 10002"
      />
      <EventSlice
        eventTitle="Picture Day: The Art of Self-Portraiture"
        eventDate="Saturday, April 26th, 2025"
        eventTime="9:00am - 11:00am"
        eventImgUrl="https://49market.s3.amazonaws.com/2025-04-26-Picture-Day.png"
        eventSubtitle="with Matthieu Dougherty"
        eventUrl="/event/252"
        soldOut={false}
        ticketUrl="https://buy.stripe.com/cN28Ac8P26aaceZ0fC"
        location="49 Market St, NY, NY 10002"
      />
      <EventSlice
        eventTitle="Build-a-Lamp"
        eventDate="Saturday, April 26th, 2025"
        eventTime="9:00am - 11:00am"
        eventImgUrl="https://49market.s3.amazonaws.com/2025-04-26-Lamp.png"
        eventSubtitle=""
        eventUrl="/event/252"
        soldOut={false}
        ticketUrl="https://buy.stripe.com/7sI03G2qEeGG3It1jL"
        location="224 Franklin St, NY, NY 10002"
      />
      <EventSlice
        eventTitle="The Art of Crochet"
        eventDate="Sunday, April 27th, 2025"
        eventTime="9:00am - 11:00am"
        eventImgUrl="https://49market.s3.amazonaws.com/2025-04-27-crochet.png"
        eventSubtitle="with Team HM"
        eventUrl="/event/252"
        soldOut={false}
        ticketUrl="https://buy.stripe.com/4gw17KfdqeGGen77Ie"
        location="49 Market St, NY, NY 10002"
      />
      <EventSlice
        eventTitle="Figure Drawing"
        eventDate="Monday, April 28th, 2025"
        eventTime="7:00pm - 9:00pm"
        eventImgUrl="https://49market.s3.amazonaws.com/2025-04-28-figure-drawing.png"
        eventSubtitle="with special guest, Bola Bee."
        eventUrl="/event/252"
        soldOut={false}
        ticketUrl="https://buy.stripe.com/3cs7w8e9m7ee4Mxd2v"
        location="49 Market St, NY, NY 10002"
      />
      <EventSlice
        eventTitle="Figure Drawing"
        eventDate="Tuesday, April 29th, 2025"
        eventTime="7:00pm - 9:00pm"
        eventImgUrl="https://49market.s3.amazonaws.com/2025-04-29-figure-drawing.png"
        eventSubtitle="with special guest, Shea."
        eventUrl="/event/252"
        soldOut={false}
        ticketUrl="https://buy.stripe.com/3cs9Eg9T67eefrb4w0"
        location="49 Market St, NY, NY 10002"
      />
      <EventSlice
        eventTitle="Figure Drawing"
        eventDate="Wednesday, April 30th, 2025"
        eventTime="7:00pm - 9:00pm"
        eventImgUrl="https://49market.s3.amazonaws.com/2025-04-30-figure-drawing.png"
        eventSubtitle="with special guest, Lauren Gerrie."
        eventUrl="/event/252"
        soldOut={false}
        ticketUrl="https://buy.stripe.com/aEU5o07KYeGGfrb2nH"
        location="49 Market St, NY, NY 10002"
      />

      <View style={{ height: 40 }} />
      <Image
        source={measuringTape}
        style={{
          height: 45,
          width: 450,
          maxWidth: "100vw",
          resizeMode: "contain",
          marginTop: 40,
          paddingBottom: 40,
          alignSelf: "center",
          transform: [{ rotate: "-10deg" }],
          left: 200,
        }}
      />
    </ScrollView>
  );
};

// <EventSlice
//         key={'AOPD_JUL312022'}
//         eventTitle="The Art of Plywood Design"
//         eventDate="Sunday, July 31st, 2022"
//         eventTime="10:00am - 2:00pm"
//         eventImgUrl="https://49market.s3.amazonaws.com/2022-07-31_art-of-plywood-design.png"
//         eventSubtitle="with Rett Carraway."
//         eventUrl="/event/208"
//         soldOut={false}
//         ticketUrl="https://buy.stripe.com/dR63fSd5i5662EodUt"
//       />
